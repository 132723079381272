.flex-block{
    display: flex;
}

.flex-none{
    display: flex;
}

.block-none{
    display: block;
}

.none-block{
    display: none;
}

.ax{
    display: flex;
}

.axi{
    display: none;
}

.pagepadding{
    padding: 64px;
}

@media screen and (max-width: 768px) {
    .flex-block{
        display: block !important;
    }
    .flex-none{
        display: none !important;
    }
    .block-none{
        display: none !important;
    }
    .pagepadding{
        padding: 34px !important;
    }
    .none-block{
        display: block !important;
    }
}

@media screen and (max-width: 968px) {
    .ax{
        display: none !important;
    }
    .axi{
        display: block !important;
    }
}