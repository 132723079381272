.headerpadding{
    padding-left: 128px;
    padding-right: 128px;
}

@media screen and (max-width: 768px) {
    .headerpadding{
        padding-left: 24px !important;
        padding-right: 24px !important;
    }
}