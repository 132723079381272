.block-none{
    display: block;
}

.block-flex{
    display: block;
}

@media screen and (max-width: 768px) {
    .block-none{
        display: none !important;
    }
    .block-flex{
        display: flex !important;
    }
    .mbb{
        margin-bottom: 8px;
    }
}