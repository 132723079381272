.flex-block{
    display: flex;
}

.flex-none{
    display: flex;
}

.block-none{
    display: block;
}

.none-block{
    display: none;
}

.pagepadding{
    padding: 64px;
}

@keyframes slide {
    0% {
        transform: translateX(0)
    }
    100% {
        transform: translateX(calc(-250px * 7))
    }
}

.slider {
    overflow: hidden;
    margin: auto;
    display: absolute;
}

.slide-track {
    animation: slide 80s linear infinite;
    display: flex;
    width: calc(250px * 14);
}

@media screen and (max-width: 768px) {
    .flex-block{
        display: block !important;
    }
    .flex-none{
        display: none !important;
    }
    .block-none{
        display: none !important;
    }
    .pagepadding{
        padding: 34px !important;
    }
    .none-block{
        display: block !important;
    }
}