.flex-block{
    display: flex;
}

.flex-none{
    display: flex;
}

.block-none{
    display: block;
}

.none-block{
    display: none;
}

.none-flex{
    display: none;
}

.pagepadding{
    padding: 64px;
}

.bmenupadding{
    padding-left: 54px;
    padding-right: 54px;
}

@media screen and (max-width: 768px) {
    .flex-block{
        display: block !important;
    }
    .flex-none{
        display: none !important;
    }
    .block-none{
        display: none !important;
    }
    .pagepadding{
        padding: 34px !important;
    }
    .none-block{
        display: block !important;
    }
    .none-flex{
        display: flex !important;
    } 
    .bmenupadding{
        padding-left: 14px !important;
        padding-right: 14px !important;
    }
}